* {
    margin: 0;
    padding: 0;

}


body {
    font-family: "微软雅黑";


    display: flex;
    /* flex-direction: row; */
    justify-self: center;
    align-items: center;
    flex-direction: column;

    overflow-x: hidden;

    min-height: 100vh;
}

.header {
    z-index: 100;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0.2em;
    color: #222;
    align-items: center;

    box-shadow: 0 0 0.2em #0005;
    background-color: #ffffff10;
    backdrop-filter: blur(10px);

    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 5em;
}

.header>div {
    margin: 0.5em;

    cursor: pointer;
    transition: color 200ms ease-in-out;
}


.header>div:hover {
    margin: 0.5em;

    cursor: pointer;

    color: #43a4ff;
}

.btn1 {
    background-color: #2980c3;
    color: #fff;
    transition: background-color 200ms ease-in-out;
    border-radius: 0.2em;
    box-shadow: 0 0 0.2em #0005;

    user-select: none;
    cursor: pointer;

    padding: 0.5em 1em;

}

.btn1:hover {
    background-color: #1e6194;
}

.btn2 {
    color: #fff;
    background-color: #293cbe;
    transition: background-color 200ms ease-in-out;
    border-radius: 0.2em;
    box-shadow: 0 0 0.2em #0005;

    user-select: none;
    cursor: pointer;

    padding: 0.5em 1em;
}

.btn2:hover {
    background-color: #1d2b87;
}

.leftitem {
    background-image: url("images/1.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;



    width: 40%;
    padding: 0.3em 0.6em;

    flex-shrink: 0;

    padding: 5em;
    box-sizing: border-box;
    color: #fff;
}

.leftitem2 {
    background-image: url("images/2.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;



    width: 40%;
    padding: 0.3em 0.6em;

    flex-shrink: 0;

    padding: 5em;
    box-sizing: border-box;
    color: #fff;
}

.box {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.box>.title {
    text-align: center;
    margin: 1em;
    color: #333;
    font-size: 1.5em;
    font-weight: bold;
}

.box>.ctx {
    display: flex;
    flex-direction: row-reverse;

    gap: 1em;

    /* height: 60em; */

}

.list {
    flex-grow: 1;

    gap: 0.5em;
    display: flex;
    flex-wrap: wrap;
}

.list>div {
    min-height: 10em;
    display: flex;

    flex-grow: 1;


    height: 5em;
    background-image: var(--bg-image);
    background-position: 50%;
    background-size: cover;
    border-radius: 0.3em;

    transition: all 200ms ease-in-out;

    text-align: center;
    display: flex;
    justify-content: center;
    margin: 1em;
    /* margin-bottom: 10%; */
    min-width: 10em;
    cursor: pointer;

}

.list>div:hover {
    transform: translateY(-0.2em) scale(1.02);
    box-shadow: 0 0 0.5em #0005;

}

.list>div>div {
    background-color: rgba(0, 0, 0, 0.183);
    backdrop-filter: blur(10px);

    width: 100%;
    height: 100%;
    position: relative;
    /* top: 100%; */
    color: #ffffff;
    word-break: keep-all;

    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    justify-content: center;

    align-items: center;

    border-radius: 0.4em;
    padding: 0.2em;

    opacity: 0;

    top: 1em;

    transition: all 0.2s ease-in-out;
}

.list>div>div:hover {
    opacity: 1;
    top: 0;
}



.list>div>div>div:nth-child(1) {
    font-size: 1.5em;

}

.list>div>div>div {
    text-shadow: 0 0 0.2em #000;

}

.list>div>div>div:nth-child(2) {
    font-size: 1em;
    color: #eda766;
    font-weight: bolder;
}

/* .list>div>div>div:nth-child(3) {
    font-size: 1.5em;
} */

.main {
    width: 80%;
    max-width: 100vw;
    min-width: 40em;
    min-height: 100vh;
}

.link {
    color: #43a4ff;

}

.big {
    font-size: 2em;
}

.footer {
    border-top: 1px solid #ccc;
    background-color: #fff;
    padding: 1em;
    text-align: center;
    color: #333;
    font-size: 0.8em;
    bottom: 0;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

}

.footer>div {
    margin: 0.5em;
}

.sousuo {
    margin-top: 3em;

    width: 50vw;
    border: none;
    outline: none;
    border: #27dbcf;
    border-width: 1px;
    border-style: solid;
    border-radius: 2em;
    display: flex;
    align-items: center;
    overflow: hidden;
    /* padding: 0em 0em 0em 2em; */
    /* height: 2em; */
}

.sousuo>img {
    width: 1em;
    height: 1em;
    margin: 0.5em;
}

.sousuo>input {
    border: none;
    outline: none;
    flex-grow: 1;
    background: none;
}

.sousuo>div {
    font-weight: 900;
    background-color: #27dbcf;
    padding: 0.5em 2em;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
}

.sousuo>div:hover {
    background-color: #298c85;
}

a {
    font-size: 15px;
    color: rgb(171, 175, 180);
    text-decoration: none;
}

a:hover {
    color: #04f818;
}